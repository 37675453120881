import React, { useEffect, useRef } from 'react';
import Dropzone from 'react-dropzone';

const MyDropzoneCopy = ({ onDrop, accept, placeholder, disabled, height, maxSize = Infinity }) => {
    useEffect(() => {
        const handlePaste = (event) => {
            const items = event.clipboardData.items;
            const files = [];
            for (let i = 0; i < items.length; i++) {
                if (items[i].kind === 'file') {
                    const file = items[i].getAsFile();
                    files.push(file);
                }
            }
            if (files.length > 0) {
                onDrop(files);
            }
        };

        document.addEventListener('paste', handlePaste);

        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, [onDrop]);

    return (
        <Dropzone {...{ onDrop, accept, multiple: false, disabled, height, maxSize }}>
            {({ getRootProps, getInputProps }) => (
                <section className="dropzone-wrapper" style={{ height: height ? height : 50 }}>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className="m-0" style={{ textAlign: 'center', width: '100%' }}>
                            {placeholder ? placeholder : 'Arrastre o haga clic para seleccionar el archivo o pegue el archivo'}
                        </p>
                    </div>
                </section>
            )}
        </Dropzone>
    );
};

export default MyDropzoneCopy;