import gql from 'graphql-tag'

const SireModel = {
    getWs01: (client, {format, page, perPage, conTotales}, recursos) => client.query({
        query: gql`query ws01($format: String, $page: Int!, $perPage: Int!, $conTotales: Boolean) {
            ws01(format: $format, page: $page, perPage: $perPage, conTotales: $conTotales) {
                ${recursos}
            }
        }`,
        variables: {format, page, perPage, conTotales},
        fetchPolicy: 'no-cache'
    }),
    getResumenRVIE: (client, recursos) => client.query({
        query: gql`query {
            resumenRVIE {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    getRVIENoIncluidos: (client, recursos) => client.query({
        query: gql`query {
            RVIENoIncluidos {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    getPreliminarRVIE: (client, recursos) => client.query({
        query: gql`query {
            PreliminarRVIE {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    getPreliminaresRegistro: (client, recursos) => client.query({
        query: gql`query {
            PreliminaresRegistro {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    getCompararRVIE: (client, recursos) => client.query({
        query: gql`query {
            comparacionRVIE {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    ControlProcesoRVIE: (client, {codLibro, periodo}, recursos) => client.query({
        query: gql`query ControlProcesoRVIE($codLibro: String!,$periodo: String) {
            ControlProcesoRVIE(codLibro: $codLibro, periodo: $periodo) {
                ${recursos}
            }
        }`,
        variables: {codLibro, periodo},
        fetchPolicy: 'no-cache'
    }),
    PreliminaresRegistroRVIE: (client, recursos) => client.query({
        query: gql`query {
            PreliminaresRegistroRVIE {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    CasillasPropuestasPreliminarRVIE: (client, recursos) => client.query({
        query: gql`query {
            CasillasPropuestasPreliminarRVIE {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    CasillasPropuestasRVIE: (client, recursos) => client.query({
        query: gql`query {
            CasillasPropuestasRVIE {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    ResumenInconsistenciasRVIE: (client, recursos) => client.query({
        query: gql`query {
            ResumenInconsistenciasRVIE {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    eliminaPreliminar: (client, {id, codTipoRegistro, codLibro}, recursos) => client.query({
        query: gql`query EliminaPreliminar($id: String!, $codTipoRegistro: String!, $codLibro: String!) {
            EliminaPreliminar(id: $id, codTipoRegistro: $codTipoRegistro, codLibro: $codLibro) {
                ${recursos}
            }
        }`,
        variables: {id, codTipoRegistro, codLibro},
        fetchPolicy: 'no-cache'
    }),
    uploadFile: (client, recursos) => client.query({
        query: gql`query {
            RVIENoIncluidos {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    deletePropuestaSwa513: (client, {codCar, codTipoCDP, numSerieCDP, numCDP}, recursos) => client.query({
        query: gql`query Swa513($codCar: String!, $codTipoCDP: String!, $numSerieCDP: String!, $numCDP: String!) {
            Swa513(codCar: $codCar, codTipoCDP: $codTipoCDP, numSerieCDP: $numSerieCDP, numCDP: $numCDP) {
                ${recursos}
            }
        }`,
        variables: {codCar, codTipoCDP, numSerieCDP, numCDP},
        fetchPolicy: 'no-cache'
    }),
    deletePreliminarSwa514: (client, {codCar, codTipoCDP, numSerieCDP, numCDP}, recursos) => client.query({
        query: gql`query Swa514($codCar: String!, $codTipoCDP: String!, $numSerieCDP: String!, $numCDP: String!) {
            Swa514(codCar: $codCar, codTipoCDP: $codTipoCDP, numSerieCDP: $numSerieCDP, numCDP: $numCDP) {
                ${recursos}
            }
        }`,
        variables: {codCar, codTipoCDP, numSerieCDP, numCDP},
        fetchPolicy: 'no-cache'
    }),
    getSwa508: (client, recursos) => client.query({
        query: gql`query {
            Swa508 {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    deleteSwa515: (client, recursos) => client.query({
        query: gql`query {
            Swa515 {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    getSwa516: (client, {numTicket}, recursos) => client.query({
        query: gql`query Swa516($numTicket: String) {
            Swa516(numTicket: $numTicket) {
                ${recursos}
            }
        }`,
        variables: {numTicket},
        fetchPolicy: 'no-cache'
    }),
    getSwa517: (client, {nomArchivoReporte, codProceso, period, numTicket, codTipoAchivoReporte}, recursos) => client.query({
        query: gql`query Swa517($nomArchivoReporte: String!, $codProceso: String, $period: String, $numTicket: String, $codTipoAchivoReporte: String) {
            Swa517(nomArchivoReporte: $nomArchivoReporte, codProceso: $codProceso, period: $period, numTicket: $numTicket, codTipoAchivoReporte: $codTipoAchivoReporte) {
                ${recursos}
            }
        }`,
        variables: {nomArchivoReporte, codProceso, period, numTicket, codTipoAchivoReporte},
        fetchPolicy: 'no-cache'
    }),
    getSwa518: (client, {codTipoArchivo}, recursos) => client.query({
        query: gql`query Swa518($codTipoArchivo: String!) {
            Swa518(codTipoArchivo: $codTipoArchivo) {
                ${recursos}
            }
        }`,
        variables: {codTipoArchivo},
        fetchPolicy: 'no-cache'
    }),
    getPropuestaCompra: (client, {format, page, perPage, conTotales}, recursos) => client.query({
        query: gql`query propuestaRCE($format: String, $page: Int!, $perPage: Int!, $conTotales: Boolean) {
            propuestaRCE(format: $format, page: $page, perPage: $perPage, conTotales: $conTotales) {
                ${recursos}
            }
        }`,
        variables: {format, page, perPage, conTotales},
        fetchPolicy: 'no-cache'
    }),
    uploadSwa503: (client, {codProceso}, recursos) => client.query({
        query: gql`query Swa503($codProceso: String!) {
            Swa503(codProceso: $codProceso) {
                ${recursos}
            }
        }`,
        variables: {codProceso},
        fetchPolicy: 'no-cache'
    }),
    uploadSwa504: (client, {ids}, recursos) => client.query({
        query: gql`query Swa504($ids: JSON!) {
            Swa504(ids: $ids) {
                ${recursos}
            }
        }`,
        variables: {ids},
        fetchPolicy: 'no-cache'
    }),
    downloadSwa533: (client, recursos) => client.query({
        query: gql`query {
            Swa533 {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    downloadSwa534: (client, recursos) => client.query({
        query: gql`query {
            Swa534 {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),

    getResumenRCE: (client, recursos) => client.query({
        query: gql`query {
            resumenRCE {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    ResumenInconsistenciasRCE: (client, recursos) => client.query({
        query: gql`query {
            ResumenInconsistenciasRCE {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    documentByFc: (client, {fila, codProceso}, recursos) => client.query({
        query: gql`query documentByFc($fila: String!, $codProceso: String!) {
            documentByFc(fila: $fila, codProceso: $codProceso) {
                ${recursos}
            }
        }`,
        variables: {fila, codProceso},
        fetchPolicy: 'no-cache'
    }),
    downloadFileRVIE: (client, {extension}, recursos) => client.query({
        query: gql`query downloadFileRVIE($extension: String!) {
            downloadFileRVIE(extension: $extension) {
                ${recursos}
            }
        }`,
        variables: {extension},
        fetchPolicy: 'no-cache'
    }),
    downloadFileRCE: (client, {extension}, recursos) => client.query({
        query: gql`query downloadFileRCE($extension: String!) {
            downloadFileRCE(extension: $extension) {
                ${recursos}
            }
        }`,
        variables: {extension},
        fetchPolicy: 'no-cache'
    }),
    uploadRce503: (client, {codProceso}, recursos) => client.query({
        query: gql`query Rce503($codProceso: String!) {
            Rce503(codProceso: $codProceso) {
                ${recursos}
            }
        }`,
        variables: {codProceso},
        fetchPolicy: 'no-cache'
    }),
    padronRVIE: (client, {codLibro}, recursos) => client.query({
        query: gql`query padronRVIE($codLibro: String!) {
            padronRVIE(codLibro: $codLibro) {
                ${recursos}
            }
        }`,
        variables: {codLibro},
        fetchPolicy: 'no-cache'
    }),
    DetallePreliminarRegistro: (client, {codLibro, codTipoRegistro, desTipoRegistro}, recursos) => client.query({
        query: gql`query detallePreliminarRegistro($codLibro: String!, $codTipoRegistro: String! , $desTipoRegistro: String) {
            detallePreliminarRegistro(codLibro: $codLibro, codTipoRegistro: $codTipoRegistro, desTipoRegistro: $desTipoRegistro) {
                ${recursos}
            }
        }`,
        variables: {codLibro, codTipoRegistro, desTipoRegistro},
        fetchPolicy: 'no-cache'
    }),
    getRce502: (client, recursos) => client.query({
        query: gql`query {
            Rce502 {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    getCompararRCE: (client, recursos) => client.query({
        query: gql`query {
            comparacionRCE {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    uploadRce506: (client, {ids}, recursos) => client.query({
        query: gql`query Rce506($ids: JSON!) {
            Rce506(ids: $ids) {
                ${recursos}
            }
        }`,
        variables: {ids},
        fetchPolicy: 'no-cache'
    }),
    getRce534: (client, {codTipoArchivo}, recursos) => client.query({
        query: gql`query Rce534($codTipoArchivo: String!) {
            Rce534(codTipoArchivo: $codTipoArchivo) {
                ${recursos}
            }
        }`,
        variables: {codTipoArchivo},
        fetchPolicy: 'no-cache'
    }),
    verificaPropuesta: (client, recursos) => client.query({
        query: gql`query {
            VerificaPropuesta {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    registra: (client, {registra}, recursos) => client.query({
        query: gql`query registra($registra: JSON!) {
            registra(registra: $registra) {
                ${recursos}
            }
        }`,
        variables: {registra},
        fetchPolicy: 'no-cache'
    }),
    swa511: (client, {tipo_cambio}, recursos) => client.query({
        query: gql`query Swa511($tipo_cambio: JSON!) {
            Swa511(tipo_cambio: $tipo_cambio) {
                ${recursos}
            }
        }`,
        variables: {tipo_cambio},
        fetchPolicy: 'no-cache'
    }),
    compareRVIE: (client, {onlyRecords, numTicket}, recursos) => client.query({
        query: gql`query compareRVIE($onlyRecords: Boolean!, $numTicket: String) {
            compareRVIE(onlyRecords: $onlyRecords, numTicket: $numTicket) {
                ${recursos}
            }
        }`,
        variables: {onlyRecords, numTicket: numTicket && numTicket !== '' ? numTicket : null},
        fetchPolicy: 'no-cache'
    }),
    compareRCE: (client, {onlyRecords, numTicket}, recursos) => client.query({
        query: gql`query compareRCE($onlyRecords: Boolean!, $numTicket: String) {
            compareRCE(onlyRecords: $onlyRecords, numTicket: $numTicket) {
                ${recursos}
            }
        }`,
        variables: {onlyRecords, numTicket: numTicket && numTicket !== '' ? numTicket : null},
        fetchPolicy: 'no-cache'
    }),
    Rce5111213: (client, {valorRCF, valorCFE, factProrrata}, recursos) => client.query({
        query: gql`query Rce5111213($valorRCF: Float!, $valorCFE: Float!, $factProrrata: Float!) {
            Rce5111213(valorRCF: $valorRCF, valorCFE: $valorCFE, factProrrata: $factProrrata) {
                ${recursos}
            }
        }`,
        variables: {valorRCF: Number(valorRCF), valorCFE: Number(valorCFE), factProrrata: Number(factProrrata)},
        fetchPolicy: 'no-cache'
    }),
    Rce514: (client, recursos) => client.query({
        query: gql`query {
            Rce514 {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    Rce505: (client, recursos) => client.query({
        query: gql`query {
            Rce505 {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    downloadFileRCE85: (client, {extension}, recursos) => client.query({
        query: gql`query downloadFileRCE85($extension: String!) {
            downloadFileRCE85(extension: $extension) {
                ${recursos}
            }
        }`,
        variables: {extension},
        fetchPolicy: 'no-cache'
    }),
    getPreliminarRCE: (client, recursos) => client.query({
        query: gql`query {
            PreliminarRCE {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    deleteRce517: (client, {indicador}, recursos) => client.query({
        query: gql`query Rce517($indicador: String!) {
            Rce517(indicador: $indicador) {
                ${recursos}
            }
        }`,
        variables: {indicador},
        fetchPolicy: 'no-cache'
    }),
    Rce504: (client, recursos) => client.query({
        query: gql`query {
            Rce504 {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    Swa509: (client, recursos) => client.query({
        query: gql`query {
            Swa509 {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    emailRegistro: (client, {desCorreoOpcional, numTicket}, recursos) => client.query({
        query: gql`query emailRegistro($desCorreoOpcional: String!, $numTicket: String!) {
            emailRegistro(desCorreoOpcional: $desCorreoOpcional, numTicket: $numTicket) {
                ${recursos}
            }
        }`,
        variables: {desCorreoOpcional, numTicket},
        fetchPolicy: 'no-cache'
    }),
    uploadSwa506: (client, {codProceso, ajustes}, recursos) => client.query({
        query: gql`query Swa506($codProceso: String!, $ajustes: JSON!) {
            Swa506(codProceso: $codProceso, ajustes: $ajustes) {
                ${recursos}
            }
        }`,
        variables: {codProceso, ajustes},
        fetchPolicy: 'no-cache'
    }),
    uploadSwa507: (client, {codProceso, ajustes}, recursos) => client.query({
        query: gql`query Swa507($codProceso: String!, $ajustes: JSON!) {
            Swa507(codProceso: $codProceso, ajustes: $ajustes) {
                ${recursos}
            }
        }`,
        variables: {codProceso, ajustes},
        fetchPolicy: 'no-cache'
    }),
    downloadAjustesRVIE: (client, {extension, ajustes, type}, recursos) => client.query({
        query: gql`query downloadAjustesRVIE($extension: String!, $ajustes: JSON!, $type: String!) {
            downloadAjustesRVIE(extension: $extension, ajustes: $ajustes, type: $type) {
                ${recursos}
            }
        }`,
        variables: {extension, ajustes, type},
        fetchPolicy: 'no-cache'
    }),
    downloadAjustesRCE: (client, {extension, ajustes, type}, recursos) => client.query({
        query: gql`query downloadAjustesRCE($extension: String!, $ajustes: JSON!, $type: String!) {
            downloadAjustesRCE(extension: $extension, ajustes: $ajustes, type: $type) {
                ${recursos}
            }
        }`,
        variables: {extension, ajustes, type},
        fetchPolicy: 'no-cache'
    }),
    uploadRce518: (client, {codProceso, ajustes}, recursos) => client.query({
        query: gql`query Rce518($codProceso: String!, $ajustes: JSON!) {
            Rce518(codProceso: $codProceso, ajustes: $ajustes) {
                ${recursos}
            }
        }`,
        variables: {codProceso, ajustes},
        fetchPolicy: 'no-cache'
    }),
    uploadRce524: (client, {codProceso, ajustes}, recursos) => client.query({
        query: gql`query Rce524($codProceso: String!, $ajustes: JSON!) {
            Rce524(codProceso: $codProceso, ajustes: $ajustes) {
                ${recursos}
            }
        }`,
        variables: {codProceso, ajustes},
        fetchPolicy: 'no-cache'
    }),
    ReporteCumplimiento: (client, {codLibro}, recursos) => client.query({
        query: gql`query ReporteCumplimiento($codLibro: String!) {
            ReporteCumplimiento(codLibro: $codLibro) {
                ${recursos}
            }
        }`,
        variables: {codLibro},
        fetchPolicy: 'no-cache'
    }),
    downloadConstanciaRecepcion: (client, {nomConstanciaRecepcion, tipo}, recursos) => client.query({
        query: gql`query DownloadConstanciaRecepcion($nomConstanciaRecepcion: String!, $tipo: String!) {
            DownloadConstanciaRecepcion(nomConstanciaRecepcion: $nomConstanciaRecepcion, tipo: $tipo) {
                ${recursos}
            }
        }`,
        variables: {nomConstanciaRecepcion, tipo},
        fetchPolicy: 'no-cache'
    }),
    ConstanciaRecepcion: (client, {codLibro}, recursos) => client.query({
        query: gql`query ConstanciaRecepcion($codLibro: String!) {
            ConstanciaRecepcion(codLibro: $codLibro) {
                ${recursos}
            }
        }`,
        variables: {codLibro},
        fetchPolicy: 'no-cache'
    }),
    HistorialRegistros: (client, {codLibro}, recursos) => client.query({
        query: gql`query HistorialRegistros($codLibro: String!) {
            HistorialRegistros(codLibro: $codLibro) {
                ${recursos}
            }
        }`,
        variables: {codLibro},
        fetchPolicy: 'no-cache'
    }),
    consolidadoRVIE: (client, {perTributario, codTipoRegistro}, recursos) => client.query({
        query: gql`query consolidadoRVIE($perTributario: String!, $codTipoRegistro: String!) {
            consolidadoRVIE(perTributario: $perTributario, codTipoRegistro: $codTipoRegistro) {
                ${recursos}
            }
        }`,
        variables: {perTributario, codTipoRegistro},
        fetchPolicy: 'no-cache'
    }),
    consolidadoRCE: (client, {perTributario, codTipoRegistro}, recursos) => client.query({
        query: gql`query consolidadoRCE($perTributario: String!, $codTipoRegistro: String!) {
            consolidadoRCE(perTributario: $perTributario, codTipoRegistro: $codTipoRegistro) {
                ${recursos}
            }
        }`,
        variables: {perTributario, codTipoRegistro},
        fetchPolicy: 'no-cache'
    }),
    inconsistenciaRCE: (client, {codTipoCDP, numSerieCDP, numCDP, codCar, codOrigen}, recursos) => client.query({
        query: gql`query inconsistenciaRCE($codTipoCDP: String!, $numSerieCDP: String!, $numCDP: String!, $codCar: String!, $codOrigen: String!) {
            inconsistenciaRCE(codTipoCDP: $codTipoCDP, numSerieCDP: $numSerieCDP, numCDP: $numCDP, codCar: $codCar, codOrigen: $codOrigen) {
                ${recursos}
            }
        }`,
        variables: {codTipoCDP, numSerieCDP, numCDP, codCar, codOrigen},
        fetchPolicy: 'no-cache'
    }),
    deletePropuestaRce515: (client, {codCar, codTipoCDP, numSerieCDP, numCDP}, recursos) => client.query({
        query: gql`query Rce515($codCar: String!, $codTipoCDP: String!, $numSerieCDP: String!, $numCDP: String!) {
            Rce515(codCar: $codCar, codTipoCDP: $codTipoCDP, numSerieCDP: $numSerieCDP, numCDP: $numCDP) {
                ${recursos}
            }
        }`,
        variables: {codCar, codTipoCDP, numSerieCDP, numCDP},
        fetchPolicy: 'no-cache'
    }),
    incluirExcluirRCE: (client, {type, ies}, recursos) => client.query({
        query: gql`query IncluirExcluirRCE($type: String!, $ies: JSON!) {
            IncluirExcluirRCE(type: $type, ies: $ies) {
                ${recursos}
            }
        }`,
        variables: {type, ies},
        fetchPolicy: 'no-cache'
    }),
    excluidosRCE: (client, recursos) => client.query({
        query: gql`query {
            ExcluidosRCE {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    Swa510: (client, {codCar}, recursos) => client.query({
        query: gql`query Swa510($codCar: String!) {
            Swa510(codCar: $codCar) {
                ${recursos}
            }
        }`,
        variables: {codCar},
        fetchPolicy: 'no-cache'
    }),
    downloadExcelRce: (client, recursos) => client.query({
        query: gql`query {
            downloadExcelRce {
                ${recursos}
            }
        }`,
        context: {timeout: 960000},
        fetchPolicy: 'no-cache'
    }),
    importTo: (client, {ids}, recursos) => client.query({
        query: gql`query importTo($ids: JSON!) {
            importTo(ids: $ids) {
                ${recursos}
            }
        }`,
        context: {timeout: 480000},
        variables: {ids},
        fetchPolicy: 'no-cache'
    }),
    downloadExcelRvie: (client, recursos) => client.query({
        query: gql`query {
            downloadExcelRvie {
                ${recursos}
            }
        }`,
        context: {timeout: 960000},
        fetchPolicy: 'no-cache'
    }),
    periodosPresentados: (client, {codLibro}, recursos) => client.query({
        query: gql`query periodosPresentados($codLibro: String!) {
            periodosPresentados(codLibro: $codLibro) {
                ${recursos}
            }
        }`,
        variables: {codLibro},
        fetchPolicy: 'no-cache'
    }),
    downloadExcelConsolidadoRVIE: (client, {perTributario, codTipoRegistro}, recursos) => client.query({
        query: gql`query downloadExcelConsolidadoRVIE($perTributario: String!, $codTipoRegistro: String!) {
            downloadExcelConsolidadoRVIE(perTributario: $perTributario, codTipoRegistro: $codTipoRegistro) {
                ${recursos}
            }
        }`,
        variables: {perTributario, codTipoRegistro},
        fetchPolicy: 'no-cache'
    }),
    downloadExcelConsolidadoRCE: (client, { perTributario, codTipoRegistro}, recursos) => client.query({
        query: gql`query downloadExcelConsolidadoRCE($perTributario: String!, $codTipoRegistro: String!) {
            downloadExcelConsolidadoRCE(perTributario: $perTributario, codTipoRegistro: $codTipoRegistro) {
                ${recursos}
            }
        }`,
        variables: {perTributario, codTipoRegistro},
        fetchPolicy: 'no-cache'
    }),
    RcePreAjustes: (client, recursos) => client.query({
        query: gql`query RcePreAjustes {
            RcePreAjustes {
                ${recursos}
            }
        }`,
        fetchPolicy: 'no-cache'
    }),
    RceDeletePreAjustes: (client, {indicador, selectedRows}, recursos) => client.query({
        query: gql`query RceDeletePreAjustes($indicador: String!, $selectedRows: JSON!) {
            RceDeletePreAjustes(indicador: $indicador, selectedRows: $selectedRows) {
                ${recursos}
            }
        }`,
        variables: {indicador, selectedRows},
        fetchPolicy: 'no-cache'
    }),
    RceSendAjustesPosteriores: (client, {indicador}, recursos) => client.query({
        query: gql`query RceSendAjustesPosteriores($indicador: String!) {
            RceSendAjustesPosteriores(indicador: $indicador) {
                ${recursos}
            }
        }`,
        variables: {indicador},
        fetchPolicy: 'no-cache'
    })
}
export default SireModel
